/** @jsx jsx */
import { jsx, Grid, Flex, Button, Text } from 'theme-ui'
import { useState, useEffect } from 'react'
import Layout from '../../components/layout'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { Archive } from '../../components/hero'
import { graphql } from 'gatsby'
import update from 'immutability-helper'
import { chunk, responsive } from '../../utils/helpers'
import { CaptionCard } from '../../components/card'
import { getCalendars } from '../../api_aws/opta'
import moment from 'moment'
import { months } from '../../components/form/dateSelect'
import MetaData from '../../components/metadata'

const ReportArchive = props => {
  const [calendars, setCalendars] = useState()
  const [calendar, setCalendar] = useState()
  const [month, setMonth] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [reports, setReports] = useState([])
  const { edges } = props.data.allContentfulMatchReport

  useEffect(() => {
    ;(async () => {
      const calendars = await getCalendars()
      if (!calendars.success || !calendars.data.length) return
      const options = calendars.data.map(x => ({
        ...x,
        value: x.id,
        label: x.name,
      }))
      setCalendars(options)
      setCalendar(options[0])
    })()
  }, [])

  useEffect(() => {
    if (!calendar) return
    const startDate = moment(calendar.startDate, 'YYYY-MM-DD[Z]')
    const endDate = moment(calendar.endDate, 'YYYY-MM-DD[Z]')
    const filtered = edges.filter(x => {
      const nodeDate = moment(x.node.date)
      const inDateRange = nodeDate >= startDate && nodeDate <= endDate
      const inMonth =
        month === false
          ? true
          : nodeDate.isSame(nodeDate.clone().set('month', month), 'month')
      return inDateRange && inMonth
    })
    const chunks = chunk(filtered, 16)
    setTotalPages(chunks.length)

    if (chunks.length) {
      setReports(r =>
        update(r, {
          $push: chunks[currentPage],
        })
      )
    }
  }, [currentPage, calendar, month, edges])

  const paginationSX = {
    justifyContent: 'center',
    paddingTop: 6,
    paddingBottom: 6,
  }

  const onCalendarChange = option => {
    setReports([])
    setCalendar(option)
  }

  const onMonthChange = option => {
    setReports([])
    setMonth(option.value)
  }

  const NoResults = () => {
    if (reports.length) return null

    return <Text my={10}>No Results</Text>
  }

  const monthOptions = months.map((x, i) => ({ label: x, value: i }))
  monthOptions.unshift({ label: 'All Months', value: false })

  return (
    <Layout {...props}>
      <MetaData title="Match Reports" />
      <Archive
        title="Match Reports"
        variant="center"
        filters={[
          {
            options: calendars,
            onChange: onCalendarChange,
          },
          {
            options: monthOptions,
            onChange: onMonthChange,
          },
        ]}
      />
      <Wrapper>
        <Inner>
          <Grid pt={4} pb={4} columns={responsive(1, 4)}>
            <NoResults />
            {reports?.map((x, i) => {
              return (
                <CaptionCard
                  {...x.node}
                  thumbnail={{ url: x.node.thumbnail?.file?.url }}
                  key={i}
                  url={`/match-report/${x.node.slug}`}
                />
              )
            })}
          </Grid>
          {currentPage < totalPages - 1 && (
            <Flex sx={paginationSX}>
              <Button
                variant="tertiary"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Load More
              </Button>
            </Flex>
          )}
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export default ReportArchive

export const pageQuery = graphql`
  query AllMatchReports {
    allContentfulMatchReport(sort: { fields: date, order: DESC }) {
      edges {
        node {
          date
          title
          slug
          excerpt
          thumbnail {
            file {
              url
            }
          }
        }
      }
    }
  }
`
